export const menu = [
  {
    title: 'Pools',
    path: '/#pools',
  },
  {
    title: 'Staking Options',
    path: '/#staking',
  },
  {
    title: 'Roadmap',
    path: '/#roadmap',
  },
  {
    title: 'Community',
    path: '/#community',
  },
  {
    title: 'Partners',
    path: '/#partner',
  },
];
